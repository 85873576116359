.kanban-column {
    min-height: 60vh;
    display: inline-block;
    vertical-align: top;
    float: none;
    max-width: 250px;
}

.kanban-row {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
}