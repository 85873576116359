body {
  margin: 0;
  font-family: 'Poppins', 'Open Sans', 'Roboto', sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.card-body {
    font-family: 'Roboto';
}

.buttons {
    font-size: small;
}
.card,
.pending {
    font-family: 'Roboto';
    font-size: medium;
}
.kanban-column {
    min-height: 60vh;
    display: inline-block;
    vertical-align: top;
    float: none;
    max-width: 250px;
}

.kanban-row {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
}
.add-button {
    font-family: 'Roboto';
    font-size: small;
}
